@use "./lim-ui";

.lim-ui-pick-list-panel-options {
  display: flex;
  column-gap: lim-ui.$spacing-medium;
}

.lim-ui-pick-lists {
  display: flex;
  flex-direction: column;
  row-gap: lim-ui.$spacing-medium;
}

.lim-ui-pick-list-search {
  margin-bottom: lim-ui.$spacing-medium;
}

.lim-ui-pick-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: lim-ui.$spacing-small;
  align-items: baseline;
}

.lim-ui-selected-pick-list-item {
  font-weight: lim-ui.$fonts-weight-extra-bold;
}

.lim-ui-desktop-only {
  display: none;
}

@include lim-ui.for-desktop-up {
  .lim-ui-pick-lists {
    flex-direction: row;
    column-gap: lim-ui.$spacing-extra-large;
  }

  .lim-ui-pick-list-right {
    grid-column: 2;
  }

  .lim-ui-desktop-only {
    display: unset;
  }
}
