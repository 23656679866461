@use "lim-ui";

.beamer_icon.active,
#beamer_icon.active {
   font-family: sans-serif !important;
   font-weight: lim-ui.$fonts-weight-extra-bold !important;
   font-size: 10px !important;
   line-height: 13px !important;
   letter-spacing: 0.4px !important;
   -webkit-font-smoothing: unset !important;

   display: flex !important;
   justify-content: center !important;
   align-items: center !important;

   background-color: lim-ui.$colors-limble-red !important;
   border: solid 2px white !important;
   color: var(--colors-white-text) !important;

   position: absolute;
   // The top, right, and inset are all overriden because beamer has multiple ways of
   // positioning the badge.
   top: -8px !important;
   right: -11px !important;
   inset: -8px -11px 8px 11px !important;

   box-sizing: content-box !important;
   width: 15px !important;
   height: 15px !important;
}
