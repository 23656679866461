.doka--dropdown {
   .doka--button-tool {
      display: none; //no need to set line widths
   }
}

.doka--root {
   .doka--markup-utils {
      display: none;
   }
}

.doka--root {
   .doka--markup-color {
      label {
         width: 2.2em;
         height: 2.2em;
      }
   }
}

.viewOnly {
   .doka--markup-utils {
      display: none;
   }
   .doka--utils {
      display: none;
   }
   .doka--button-action-confirm {
      display: none;
   }

   //override - mobile would replace the cancel with the reset button - since we don't allow the user to actually crop in view mode
   .doka--button-action-cancel {
      visibility: initial !important;
      pointer-events: all !important;
      opacity: 1 !important;
      //move the close button to the top right
      position: fixed;
   }

   .doka--menu {
      width: 53px;
   }

   .doka--crop-rect,
   .doka--toolbar,
   .doka--crop-rotator,
   .doka--button-action-reset {
      display: none;
   }
}
.doka--root[data-style-viewport="x-comfortable"].viewOnly .doka--button-action-cancel {
   right: 10px;
}
