/* Limble Logo Colors */
$colors-limble-green: #289e49;
$colors-limble-green10: #e9f5ec;
$colors-limble-green20: #d0e8d6;
$colors-limble-green60: #74c58f;
$colors-limble-red: #e62737;
$colors-limble-red10: #fde9ea;
$colors-limble-red20: #f6cfd3;
$colors-limble-red60: #f07d87;
$colors-limble-orange: #f29422;
$colors-limble-orange10: #fef4e8;
$colors-limble-orange20: #f9e6ce;
$colors-limble-orange60: #f7bf7b;
$colors-limble-blue: #5083d5;
$colors-limble-blue10: #edf2fb;
$colors-limble-blue20: #d8e2f3;
$colors-limble-blue60: #96b4e6;

/* General Colors */
$colors-limble-dark-green: #11732d;
$colors-vendor-blue: #0a4966;
$colors-purple: #4e377a;
$colors-violet: #a25ae0;
$colors-yellow: #f7e94a;
$colors-limble-navy-blue: #09182b;
$colors-youtube-red: #ff0000;
$colors-grey: #313233;
$colors-grey5: #f2f2f2;
$colors-grey10: #e8e8e8;
$colors-grey20: #dddddd;
$colors-grey30: #b3b3b3;
$colors-grey80: #5a5b5c;
$colors-white: #ffffff;
$colors-black: #000000;

/* General Colors Dark Theme */
$colors-page-background-dark: #17181a;
$colors-level-one-background-dark: #1f2229;
$colors-level-two-background-dark: #21262e;
$colors-level-two-active-background-dark: #37404d;
$colors-level-three-background-dark: #37404d;
$colors-level-three-active-background-dark: #424d5c;
$colors-level-four-background-dark: #4f5b6e;

/* Typography */
$colors-general-text: $colors-grey;
$colors-general-text-dark: #e2e2e7;
$colors-header-text: $colors-limble-navy-blue;
$colors-header-text-dark: #ededf2;
$colors-secondary-text-dark: #b8bbc1;
$colors-tertiary-text-dark: #9b9da1;
$colors-success-text-dark: #4bcc69;
$colors-warning-text-dark: #e09243;
$colors-danger-text-dark: #ff6e66;
$colors-interactive-text-dark: #64a1ee;

/* Shadows */
$colors-shadow-high: #09182b1f;
$colors-shadow-low: #09182b59;
$colors-shadow-high-dark: #0d0d0d;
$colors-shadow-low-dark: #0d0d0d;
$colors-input-focus-shadow-dark: #285031;

/* Borders */
$colors-border-light-dark: #2b313b;
$colors-border-heavy-dark: #404b59;
$colors-border-input-focus-dark: $colors-limble-green;

/* Backgrounds */
$colors-bg-dark-green: #295032;
$colors-bg-dark-red: #5f3331;
$colors-bg-dark-blue: #374f6e;
$colors-bg-dark-orange: #563e27;
