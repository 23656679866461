* {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    background: transparent;
    width: 0;
    height: 0;
  }
}

.scroll-height-inheritance {
  //this class is used to create a chain of elements with an explicit height
  //from either the top element of a page or a modal all the way
  //through to a <lim-ui-scroll-container>
  //If the scroll container's parent does not have a height, it will not
  //display the scroll bar properly
  //Linking from where the height is set at the top level to the scroll-container
  //is the best way to ensure responsive height is kept without using vh units
  // (as far as we can tell)
  display: flex;
  flex-direction: column;
  min-height: 0px;
}
