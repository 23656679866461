@use "./colors";

.table-link {
  color: var(--colors-interactive-text);
  cursor: pointer;
  text-decoration: none;
  word-break: break-word;

  &:hover {
    color: var(--colors-interactive-text);
  }
}

.blue-color-link {
  color: var(--colors-interactive-text);
  cursor: pointer;
  &:hover {
    color: var(--colors-interactive-text);
  }
}

.green-color-link {
  color: var(--colors-success-text);
  cursor: pointer;

  &:hover {
    color: var(--colors-success-text);
  }
}

.document-link {
  word-break: break-all;
  color: var(--colors-general-text);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

a {
  color: var(--colors-interactive-text);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: var(--colors-interactive-text);
  }
}
