/*
 * lim-ui.scss is used to collect all of the lim-ui library public stylesheets
 * into a single convenient place, so that users of the library only have to
 * add a single @use rule to get access to all of the stylesheets.
 *
 * Note that we could have used @forward "./foo" as foo-*; here but VSCode
 * autocompletion doesn't work well with that setup (no autocompletion at all
 * with the prefixes.
 *
 * Learn more: https://sass-lang.com/documentation/at-rules/forward
 */
@forward "./colors";
@forward "./fonts";
@forward "./layout";
@forward "./links";
