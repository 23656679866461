@use "./colors";
@use "./fonts";
@use "./layout";

.lim-ui-hide-on-mobile {
  @include layout.for-phone-only {
    display: none !important;
  }
}

.lim-ui-show-on-mobile {
  display: none;
  @include layout.for-phone-only {
    display: block;
  }
}

.lim-ui-hide-on-tablet-portrait {
  @include layout.for-tablet-portrait-and-smaller {
    display: none;
  }
}

.lim-ui-show-on-tablet-portrait {
  display: none;
  @include layout.for-tablet-portrait-and-smaller {
    display: block;
  }
}

.lim-ui-hide-on-tablet {
  @include layout.for-tablet-and-smaller {
    display: none;
  }
}

.lim-ui-show-on-tablet {
  display: none;
  @include layout.for-tablet-and-smaller {
    display: block;
  }
}

.lim-ui-show-on-big-desktop {
  display: none;
  @include layout.for-big-desktop-up {
    display: block;
  }
}

.lim-ui-hide-on-desktop {
  @include layout.for-desktop-up {
    display: none;
  }
}

.lim-ui-show-on-desktop {
  display: none;
  @include layout.for-desktop-up {
    display: inline-block;
  }
}
