.leaflet-pane.leaflet-shadow-pane {
   display: none;
}

.map-default-icon-styles {
   height: 100%;
}

.map-default-icon {
   mask: url("/assets/img/map/marker.svg") center no-repeat;
}

.leaflet-pane,
.leaflet-top,
.leaflet-bottom,
.leaflet-control {
   z-index: auto;
}
