@use "./lim-ui";

.lim-ui-list-item {
  @include lim-ui.fonts-body;
  width: 100%;
  background-color: var(--colors-list-item-background);
  padding: lim-ui.$spacing-small;
  border-radius: lim-ui.$border-radius-medium;
  margin-bottom: lim-ui.$spacing-small;

  &:hover {
    background-color: var(--colors-list-item-hover);
    .lim-ui-list-item-hover-buttons {
      @include lim-ui.for-desktop-up {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }

  &.lim-ui-disabled-list-item:hover {
    background-color: var(--colors-list-item-background);
    cursor: auto;
  }

  /* This class is recommended but optional when building list items. */
  .lim-ui-list-item-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: lim-ui.$spacing-small;
    align-items: center;
  }

  /* This class is optional when building list items. */
  .lim-ui-list-item-content-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: lim-ui.$spacing-small;
    align-items: center;
  }
}

/* Prevent extra padding added to bottom of hierarchy container */
div:not(.lim-ui-nested-list-item)
  > hierarchy-item:last-child
  > div:not(.expanded)
  div.lim-ui-list-item {
  margin-bottom: 0px;
}

.lim-ui-icon-with-text {
  display: flex;
  align-items: center;
  column-gap: lim-ui.$spacing-extra-small;
}

.lim-ui-nested-list-item {
  margin-left: lim-ui.$spacing-medium;
}

.lim-ui-hinted-list-item {
  background-color: var(--colors-list-item-hover);
}

.lim-ui-selectable-list-item {
  border: 1px solid transparent;
  padding: lim-ui.$spacing-small lim-ui.$spacing-small;
  cursor: pointer;

  &.lim-ui-hinted-list-item {
    border: 1px solid lim-ui.$colors-limble-green;
  }

  &.lim-ui-selected-list-item {
    background-color: var(--colors-list-item-success-background);
    border: 1px solid lim-ui.$colors-limble-green;
    font-weight: lim-ui.$fonts-weight-extra-bold;

    &:hover {
      background-color: lvar(--colors-list-item-success-background);
    }

    &.lim-ui-disabled-list-item:hover {
      cursor: auto;
    }
  }
}

.lim-ui-warning-list-item {
  color: var(--colors-danger-text);

  &.lim-ui-selected-list-item {
    background-color: var(--colors-list-item-danger-background);

    &:hover {
      background-color: var(--colors-list-item-danger-background);
    }
  }

  &.lim-ui-disabled-list-item:hover {
    background-color: lim-ui.$colors-limble-red;
    cursor: auto;
  }

  &.lim-ui-selectable-list-item.lim-ui-selected-list-item {
    border: 1px solid lim-ui.$colors-limble-red;
  }
}

.lim-ui-disabled-list-item {
  cursor: auto;
  opacity: 0.5;
}

.lim-ui-draggable-list-item {
  cursor: grab;

  &:hover {
    box-shadow: lim-ui.$shadow-low;
  }
}

/* 
 * A collection of list item buttons that you only display when hovering.
 * Requires the use of .lim-ui-list-item-content.
 */
.lim-ui-list-item-hover-buttons {
  @include lim-ui.for-desktop-up {
    opacity: 0;
  }
}
