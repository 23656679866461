.fc-toolbar-title {
   @include lim-ui.fonts-h2;
   @include lim-ui.for-desktop-up {
      min-width: 12.5rem;
   }
}

.fc-toolbar-chunk:nth-of-type(2) {
   order: 1;
}

.fc-toolbar-chunk:nth-of-type(1) {
   @include lim-ui.for-tablet-landscape-up {
      width: 25%; //makes it so the calendar buttons don't move depending on the month.
   }
}

.fc-toolbar-chunk > div {
   @include lim-ui.fonts-body;
   color: var(--colors-secondary-text);
   display: flex;
   align-items: center;
   &.calendar-legend {
      display: inline;
      column-gap: lim-ui.$spacing-small;

      & .legend-circle {
         height: lim-ui.$spacing-medium;
         min-width: lim-ui.$spacing-medium;
         border-radius: lim-ui.$border-radius-pill-shape;
         display: inline-block;
         vertical-align: text-bottom;
         margin-right: lim-ui.$spacing-small;

         &.pm-upcoming-color {
            background-color: lim-ui.$colors-limble-blue;
         }
         &.no-priority-color {
            background-color: #f1c40f;
         }
         &:not(:first-child) {
            margin-left: lim-ui.$spacing-small;
         }
      }
   }
}

.fc-button-group {
   @include lim-ui.fonts-body;
   border-radius: lim-ui.$border-radius-medium-large;
   max-height: lim-ui.$spacing-large;
   & button {
      &.fc-button,
      &.fc-today-button.fc-button {
         display: flex;
         align-items: center;
         border: lim-ui.$border-input;
         background-color: var(--colors-level-two-background);
         color: var(--colors-secondary-text);
         max-height: lim-ui.$spacing-large;
         &.fc-button-primary.fc-button-active {
            color: var(--colors-header-text);
            background-color: var(--colors-level-two-active-background);
            font-weight: lim-ui.$fonts-weight-extra-bold;
            border: lim-ui.$border-input;
         }
      }
   }
   @include lim-ui.for-phone-only {
      font-size: small !important;
   }
}

.fc .fc-daygrid-day-number,
.fc-col-header-cell-cushion {
   @include lim-ui.fonts-h3;
   color: var(--colors-header-text);
   text-decoration: none;
}

.fc-col-header-cell {
   background-color: var(--colors-level-two-background);
}

.fc-scrollgrid {
   width: 100%;
   max-height: 789;
   overflow: hidden;
}

.fc-daygrid-body {
   width: 100%;
   max-height: 756px;
   overflow: scroll;
}

.fc-daygrid-body::-webkit-scrollbar {
   width: 0 !important;
}

.fc-event-title-container {
   padding-left: lim-ui.$spacing-extra-small;
   & .fc-event-title {
      @include lim-ui.fonts-body;
      color: var(--colors-header-text);
   }
   & .fc-event-title.fc-sticky {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
   }
}

.fc-black-text {
   .fc-event-title {
      @include lim-ui.fonts-body;
      color: var(--colors-header-text);
   }
}

.fc-white-text {
   .fc-event-title {
      @include lim-ui.fonts-body;
      color: var(--colors-white-text);
   }
}

.fc-daygrid-event {
   white-space: normal !important;
   align-items: normal !important;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
   display: flex;
   flex-wrap: wrap;
   flex-direction: column;
   justify-content: flex-start;
   row-gap: lim-ui.$spacing-small;
}

@include lim-ui.for-tablet-landscape-up {
   .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
      display: flex;

      flex-direction: row;
      row-gap: lim-ui.$spacing-extra-small;
      column-gap: lim-ui.$spacing-medium;
   }
}

.circle-label {
   padding-right: lim-ui.$spacing-small;
}
