@use "./layout";

.loading-icon,
.limble-loading-animation {
  @include layout.flex-center-content;
}

.loading-icon > img {
  width: 70px;
}
