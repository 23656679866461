@use "lim-ui";

.printPreviewContainer {
   .hidePrint,
   .printHide {
      display: none !important;
   }

   .printViewShow,
   .printShow {
      display: inline-block !important;
   }
}

.printViewShow {
   display: none;
}

// This media query and !important are necessary to make sure that the print view doesn't
// cut off the page due to the way the webApp sets the height of body to 100%.
@media print {
   body {
      height: auto !important;
   }
}
