@use "./colors";
@use "./fonts";
@use "./layout";

lim-ui-tooltip {
  top: 0px;
  left: 0px;
  position: absolute;
  display: none;
}
