@use "./lim-ui";

// Bootstrap overrides.
.modal-content {
  border-radius: 12px;
  border: none;

  img {
    max-width: 100%;
  }
}

.modal-dialog {
  //overwrite bootstrap modal width
  max-width: 1000px !important;
}

@include lim-ui.for-phone-only {
  .modal-dialog {
    width: 100vw;
  }
}

@include lim-ui.for-tablet-portrait-up {
  .modal-dialog {
    width: 520px;
  }
}

@include lim-ui.for-desktop-up {
  .modal-dialog {
    width: 1000px;
  }
}

.modal-title-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: lim-ui.$spacing-small;
}

.modal-footer > * {
  margin: 0 !important;
}

::ng-deep .contains-modal {
  height: 100%;
}
